@import './colors.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  .ctaBtn {
    position: fixed;
    right: 20px;
    top: 20px;
    width: max-content;
    height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 0 20px;
    color: #6a4e34;
    background-color: #fff;

    svg {
      margin-right: 10px;
    }
  }
}


.loginContainer {
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  max-width: 800px;
  margin: 0 auto;

  input {
    background-color: #eee !important;
  }
}

.info {
  margin-bottom: 20px;
  display: block;
  padding: 20px;
  line-height: 1.3;
  background-color: #eee;
  border-radius: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 40px !important;
  font-weight: 300 !important;
  font-size: 1.4rem !important;
}
