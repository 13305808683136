.toastContainer {
  position: fixed;
  right: 20px;
  color: #fff;
  padding: 20px;
  top: 20px;
  min-width: 200px;
  border-radius: 5px;
  z-index: 99999;

  &.error {
    background-color: #f54242;
  }

  &.success {
    background-color: #7ee66e;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    cursor: click;
  }
}

