@import './reset.scss';
@import './colors.scss';
@import './inputs.scss';

.error {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 600;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
}

body {
  min-height: 100vh;
  background-color: #eee;
  font-family: 'Open Sans', sans-serif !important
}

.btn {
  height: 55px;
  background-color: #E51F1F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1.1rem;
  color: #fff;
}

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 200px 1fr 80px;
}

main, footer, aside {
  font-family: 'Open Sans', sans-serif !important;
}

main {
  padding: 20px;
}
